export const data = [
  {
    avatar: {
      nome: "Devix 01",
      imagem: "https://randomuser.me/api/portraits/men/61.jpg",
    },
    paginas: [
      {
        avatar: {
          nome: "Fernando",
          imagem: "https://randomuser.me/api/portraits/men/61.jpg",
        },
        midia: "images/cat1.png",
        titulo: "Titulo 1",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "",
          link: "https://randomuser.me/api/portraits/men/61.jpg",
        },
      },
      {
        avatar: {
          nome: "Sidarta",
          imagem: "images/cat2.png",
        },
        midia: "images/cat2.png",
        titulo: "Titulo 2",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Acesse o site",
          link: "https://devix.co",
        },
      },
      {
        avatar: {
          nome: "Rafael",
          imagem: "",
        },
        midia: "images/cat3.png",
        titulo: "Titulo 3",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Saiba mais",
          link: "https://randomuser.me/api/portraits/men/61.jpg",
        },
      },
    ],
  },
  {
    avatar: {
      nome: "Devix 02",
      imagem: "https://randomuser.me/api/portraits/men/60.jpg",
    },
    paginas: [
      {
        avatar: {
          nome: "Marcos",
          imagem: "",
        },
        midia: "images/cat4.png",
        titulo: "Titulo 4",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Saiba mais",
          link: "",
        },
      },
      {
        avatar: {
          nome: "",
          imagem: "https://randomuser.me/api/portraits/men/62.jpg",
        },
        midia: "",
        titulo: "Titulo 5",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Saiba mais",
          link: "",
        },
      },
      {
        avatar: {
          nome: "",
          imagem: "",
        },
        midia: "images/cat3.png",
        titulo: "Titulo 6",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Saiba mais",
          link: "",
        },
      },
      {
        avatar: {
          nome: "",
          imagem: "",
        },
        midia: "images/cat4.png",
        titulo: "Titulo 7",
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dignissim lobortis nisl, quis mattis mauris facilisis sit amet. Nulla nec rhoncus magna.",
        callToAction: {
          titulo: "Saiba mais",
          link: "",
        },
      },
    ],
  },
];
