<template>
  <div class="flex tela-geral flex-col h-screen">
    <div class="flex flex-row justify-center items-center h-screen">
      <!-- faz um loop nos stories -->
      <div
        v-for="(story, index) in stories"
        :key="index"
        :class="
          index == indexSelected
            ? `story grande absolute rounded-lg bg-red-400 transition-transform duration-200 ease-in`
            : `story pequeno absolute rounded-lg bg-red-400 transition-transform duration-200 ease-in`
        "
        style="max-height: 90vh; aspect-ratio: 9 / 16"
        :style="
          index == indexSelected
            ? `transform: translate(${90 * (index + difference)}vh) scale(0.9)`
            : `transform: translate(${
                45 * (index + difference)
              }vh) scale(0.4);cursor:pointer;`
        "
        @click="index != indexSelected ? selectSlide(index) : ''"
      >
        <div class="bg-cover bg-no-repeat h-full rounded-lg">
          <!-- Carrega e mostra a tela -->
          <div class="h-full bg-imagem">
            <img
              v-if="index == indexSelected && story.paginas[key].midia"
              class="h-full w-full rounded-lg"
              :src="story.paginas[key].midia"
            />
          </div>

          <!-- Monta o topo da tela -->
          <div
            v-touch:swipe="swipeTela(index)"
            class="area-tela w-full pt-4 absolute top-0"
            v-if="index == indexSelected"
          >
            <!-- monta os indicadores/paginadores de quantidade de stories por tela -->
            <div class="w-11/12 flex m-auto">
              <div
                class="w-full rounded-lg mr-2 relative h-auto"
                v-for="(elm, index) in story.paginas.length"
                :key="index"
              >
                <!-- length slide -->
                <div
                  class="absolute w-full rounded-lg"
                  style="
                    height: 4px;
                    background-color: rgba(255, 255, 255, 0.35);
                  "
                ></div>
                <!-- end length -->
                <div
                  class="absolute w-full rounded-lg"
                  style="height: 4px; background-color: white"
                  :style="
                    index == key
                      ? `width: ${percent}%`
                      : key > index
                      ? `width:100%`
                      : `width:0%`
                  "
                ></div>
              </div>
            </div>
            <!-- monta a área com avatar, nome e ícone de play/pause -->
            <div class="flex w-11/12 mt-4 m-auto">
              <div class="flex justify-start items-center w-1/2">
                <div
                  class="mr-4 avatar-imagem"
                  v-if="story.paginas[key].avatar.imagem"
                >
                  <img
                    :src="story.paginas[key].avatar.imagem"
                    class="rounded-full"
                  />
                </div>
                <div class="">
                  <p class="text-sm text-white font-semibold">
                    {{ story.paginas[key].avatar.nome }}
                  </p>
                </div>
              </div>
              <!-- <div class="flex justify-end items-center w-1/2 pr-2 mr-3">
                <svg
                  v-if="story.compartilhar"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  @click="pauseStory()"
                >
                  <path
                    class="st0"
                    d="M18.1,2c-0.1-0.2-0.3-0.3-0.6-0.3c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.2,0.1-0.3,0.1L2.3,8.3
                    C2.1,8.4,1.8,8.5,1.8,8.9c0,0.4,0.3,0.5,0.5,0.6l1.9,0.9c1.1,0.6,2.2,1.1,3.3,1.7c0.1,0.1,0.2,0.2,0.3,0.3c0.8,1.7,1.7,3.4,2.6,5.1
                    l0,0.1c0.1,0.2,0.2,0.5,0.7,0.5c0,0,0,0,0,0c0.4,0,0.6-0.4,0.6-0.6l0-0.1c1.9-4.4,3.8-8.8,5.7-13.2l0.2-0.4c0.2-0.4,0.4-0.8,0.5-1.3
                    C18.3,2.4,18.3,2.2,18.1,2z M15.8,5.1l-4.7,11L10,14c-0.3-0.7-0.7-1.4-1-2.1c1.9-1.9,3.8-3.8,5.7-5.7L15.8,5.1z M14.8,4.3L8,11
                    L3.9,9L14.8,4.3z"
                    fill="#fff"
                  />
                </svg>
              </div> -->
              <div class="flex justify-end items-center w-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  @click="isPaused ? playStory() : pauseStory()"
                >
                  <path
                    v-if="!isPaused"
                    d="M9 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z"
                    fill="#fff"
                  />
                  <path
                    v-else
                    d="M6 6.741c0-1.544 1.674-2.505 3.008-1.728l9.015 5.26c1.323.771 1.323 2.683 0 3.455l-9.015 5.258C7.674 19.764 6 18.803 6 17.26V6.741zM17.015 12L8 6.741V17.26L17.015 12z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>

            <div class="conteudo">
              <h1>{{ story.paginas[key].titulo }}</h1>

              <p>{{ story.paginas[key].texto }}</p>

              <div
                class="rodape"
                v-if="
                  story.paginas[key].callToAction.titulo &&
                  story.paginas[key].callToAction.link
                "
              >
                <a
                  class="btn_mais"
                  v-bind:href="story.paginas[key].callToAction.link"
                  target="_blank"
                  @click="pauseStory()"
                >
                  {{ story.paginas[key].callToAction.titulo }}
                </a>
              </div>
            </div>
          </div>

          <!-- story pequeno -->
          <div v-else>
            <div class="absolute top-1/2 left-1/2 z-50">
              <div class="flex flex-col items-center">
                <div
                  class="avatar-2 rounded-full border-2 border-indigo-400 transition-transform duration-100"
                >
                  <img :src="story.avatar.imagem" class="rounded-full" />
                </div>
                <div class="mt-2">
                  <p class="text-sm text-white font-semibold">
                    {{ story.avatar.nome }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="absolute inset-0 rounded-lg z-10"
              style="
                background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from(rgba(38, 38, _from6)),
                  to(rgba(38, 38, 38, 0))
                );
              "
            ></div>
          </div>
        </div>
        <!-- botoes de navegação -->
        <div
          v-if="index == indexSelected"
          class="absolute navegacao top-1/2 -left-11"
        >
          <i
            @click="prev(index)"
            class="fas fa-chevron-circle-left text-gray-500 cursor-pointer hover:text-gray-300 transition-colors duration-150 ease-linear"
          ></i>
        </div>
        <div
          v-if="index == indexSelected"
          class="absolute navegacao top-1/2 -right-11"
        >
          <i
            @click="next(index)"
            class="fas fa-chevron-circle-right text-gray-500 cursor-pointer hover:text-gray-300 transition-colors duration-150 ease-linear"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { data } from "../public/stories";

export default {
  name: "App",
  setup() {
    // declaration side
    const indexSelected = ref(0);
    const difference = ref(0);
    const stories = ref([]);
    const key = ref(0);
    // percent story
    const percent = ref(0);
    const timer = ref(0);
    const progress = ref(0);
    const duration = ref(7000);
    const interval = ref(0);
    const isPaused = ref(false);
    const newDur = ref(0); // duration
    const pausePer = ref(0); // pausePercent
    // ----end declaration
    const selectSlide = (index) => {
      difference.value += indexSelected.value - index;
      indexSelected.value = index;
      key.value = 0;
      reset();
    };
    const fetchStories = () => {
      stories.value = data;
      play();
    };

    // next story
    const next = (index) => {
      if (
        indexSelected.value >= stories.value.length - 1 &&
        key.value >= stories.value[indexSelected.value].paginas.length - 1
      ) {
        // Without delay
        setTimeout(() => {
          difference.value = 0;
          indexSelected.value = 0;
          key.value = 0;
        });
      } else if (
        key.value >=
        stories.value[indexSelected.value].paginas.length - 1
      ) {
        // Without delay
        setTimeout(() => {
          difference.value += index - (index + 1);
          indexSelected.value++;
          key.value = 0;
        });
      } else {
        key.value++;
      }
      reset();
    };
    // previous story
    const prev = (index) => {
      if (indexSelected.value <= 0 && key.value <= 0) {
        key.value = 0;
      } else if (key.value <= 0) {
        // Without delay
        setTimeout(() => {
          difference.value += index - (index - 1);
          indexSelected.value--;
          key.value = 0;
        });
      } else {
        key.value--;
      }
      reset();
    };
    const autoPlay = () => {
      if (
        indexSelected.value >= stories.value.length - 1 &&
        key.value >= stories.value[indexSelected.value].paginas.length - 1
      ) {
        difference.value = 0;
        indexSelected.value = 0;
        key.value = 0;
      } else if (
        key.value >=
        stories.value[indexSelected.value].paginas.length - 1
      ) {
        difference.value += indexSelected.value - (indexSelected.value + 1);
        indexSelected.value++;
        key.value = 0;
      } else {
        key.value++;
      }
      reset();
    };
    const play = () => {
      timer.value = new Date().getTime();
      progress.value = setInterval(() => {
        // forward
        let time = new Date().getTime();
        if (newDur.value > 0) {
          percent.value =
            pausePer.value +
            Math.floor((100 * (time - timer.value)) / duration.value);
        } else {
          percent.value = Math.floor(
            (100 * (time - timer.value)) / duration.value
          );
        }
      }, duration.value / 100);
      if (newDur.value > 0) {
        // interval.value = setInterval(autoPlay, newDur.value)
        interval.value = setInterval(autoPlay, duration.value);
      } else {
        interval.value = setInterval(autoPlay, duration.value);
      }
    };
    // reset play
    const reset = () => {
      percent.value = 0;
      clearInterval(progress.value);
      clearInterval(interval.value);
      // play()
      playStory();
    };
    const pauseStory = () => {
      isPaused.value = true;
      pausePer.value = percent.value;
      clearInterval(progress.value);
      clearInterval(interval.value);
      // calcul new duration
      newDur.value = duration.value - (pausePer.value * duration.value) / 100;
    };
    const playStory = () => {
      isPaused.value = false;
      percent.value = 0;
      pausePer.value = percent.value;
      clearInterval(progress.value);
      clearInterval(interval.value);
      play();
    };
    // OnMounted hook
    onMounted(() => {
      fetchStories();
    });

    const swipeTela = (index) => {
      return function (direction) {
        if (direction === "left") {
          next(index);
        } else if (direction === "right") {
          prev(index);
        }
        // console.log("Swiped item ", key.value, ": in direction ", direction);
      };
    };

    return {
      swipeTela,
      difference,
      indexSelected,
      selectSlide,
      fetchStories,
      stories,
      next,
      prev,
      key,
      percent,
      play,
      timer,
      progress,
      duration,
      reset,
      interval,
      autoPlay,
      isPaused,
      pauseStory,
      playStory,
      newDur,
      pausePer,
    };
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.fas {
  font-size: 30px;
}

.tela-geral {
  background: #25222e;
}

.story {
  aspect-ratio: 9 / 16;
  background: #000;
  max-width: 100vw !important;
}

.bg-imagem {
  height: 90vh;
  aspect-ratio: 9 / 16;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  border-radius: 5px;
}

.mt-2 {
  margin-top: 50px;
  display: block;
  transform: translate(-50%, -50%) scale(2);
}

.avatar-imagem {
  background: #fff;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50px;
}

.avatar-2 {
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%) scale(2.5);
}

.border-indigo-400 {
  border-color: rgb(106, 239, 4) !important;
}

.area-tela {
  height: 100%;
}

.conteudo {
  padding: 20px;
  color: #fff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
}

.rodape {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.btn_mais {
  background: #fff;
  color: black;
  padding: 10px 15px;
  border-radius: 50px;
  transition: 0.3s;
  align-self: center;
  animation-name: subindo;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  text-shadow: none;
  margin-bottom: 30px;
}

@keyframes subindo {
  0% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.btn_mais:hover {
  background: rgb(106, 239, 4);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease, transform 0.5s ease-in-out;
  transform: translateY(0px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

@media (max-width: 800px) {
  .story {
    overflow: hidden;
    max-height: 100vh !important;
    width: 100vw !important;
    height: 100vh !important;
    aspect-ratio: auto !important;
    transform: translate(0vh) scale(1) !important;
  }

  .bg-imagem {
    height: 100vh !important;
    width: 100vw;
  }
  .bg-imagem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .story.pequeno {
    display: none;
  }
  .navegacao {
    display: none;
  }
}
</style>
